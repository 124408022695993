<template>
  <div class="home">
    <Shortcut></Shortcut>

    <div class="banner">
        <p>
          <span>学习从猿之家开始</span>
        </p>
      <p>
        <span>一起享受学习的快乐</span>
      </p>
        <p>
          <span>理论</span>
          +
          <span>实战</span>
          任你选择
        </p>
    </div>
<!--    <JobGeneral></JobGeneral>-->
<!--    <DevCourse></DevCourse>-->
    <div v-for="(courseList,index) in allCourseList" :key="index">
      <HomeCourseList :courseList="courseList"></HomeCourseList>

    </div>

    <BusinessType></BusinessType>
    <Footer></Footer>
  </div>
</template>

<script setup>
import JobGeneral from "@/components/home/JobGeneral";
import DevCourse from "@/components/home/DevCourse";
import BusinessType from "@/components/home/BusinessType"
import Footer from "@/components/home/Footer";
import Shortcut from "@/components/common/Shortcut"
import HomeCourseList from "@/components/home/HomeCourseList";

import {onMounted, reactive, ref} from "vue";

import {getIndexCourseAPI} from "@/network/course";

let allCourseList=ref([]);
onMounted(()=>{
  getIndexCourseAPI().then(res=>{
    allCourseList.value=res;
  })
})

</script>

<style scoped lang="less">

 .banner{
   height: 340px;
   background-image: url('@/assets/images/banner/banner-left1.png'),
   url('@/assets/images/banner/banner-left2.png'),
   url('@/assets/images/banner/banner-right1.png'),
   url('@/assets/images/banner/banner-right2.png');
   background-repeat: no-repeat,no-repeat,no-repeat,no-repeat;
   background-position: top left, top left, top right, top right;
   //div{
   //  background: linear-gradient(270deg, #8ff49b 0%, #6eceff 46%, #dbcaff 100%);
   //  -webkit-background-clip: text;
   //  -webkit-text-fill-color: transparent;
   //}
   color:white;
   text-align: center;
   // 设置盒子底部对齐
   p:nth-child(1){
     display: flex;
     flex-direction: column;
     justify-content: flex-end;
     align-items: center;
     height: 140px; /* 设置容器的高度，可以根据实际情况调整 */
     //border: 1px solid #ccc; /* 可以添加边框以便更好地看到效果 */
     span{
       margin-top: auto;
       font-size: 48px;
       background: linear-gradient(270deg, #8ff49b 0%, #6eceff 46%, #dbcaff 100%);/* 渐变色 */
       -webkit-background-clip: text; /* 将渐变色裁剪到文字形状 */
       //background-clip: text; /* 标准属性，将渐变色裁剪到文字形状 */
       color: transparent; /* 让文字变成透明，以便显示渐变背景 */
     }
   }
   p:nth-child(2){
     height: 100px;
     line-height: 100px;
     span{
       font-size: 32px;
     }
   }
   p:nth-child(3){
     height: 60px;
     line-height: 60px;
     span{
       font-size: 18px;
       color:#05FCB0;
     }
   }
 }
</style>