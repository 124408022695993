import { createRouter, createWebHistory,createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import JobRoutes from "./job"
import CourseManagerRoutes from "./course-manager"

const LoginView=()=>import("@/views/LoginView")
const BoxView=()=>import("@/views/box/BoxView")
const CourseDescView=()=>import("@/views/course/CourseDescView")
const BusinessServiceView=()=>import("@/views/business/BusinessServiceView")
const OfflineTrainingView=()=>import("@/views/offline/OfflineTrainingView")
const MyCourseView=()=>import("@/views/course/MyCourseView")
const UserOrderView=()=>import("@/views/order/UserOrderView")
const PaySuccessView=()=>import("@/views/order/PaySuccessView")
const NotFoundPageView=()=>import("@/views/exception/NotFoundPageView")
const ServeErrorPageView=()=>import("@/views/exception/ServeErrorPageView")
const StudentServiceView=()=>import("@/views/course/StudentServiceView")
const StudentStudyView=()=>import("@/views/course/StudentStudyView")
const CourseUpload=()=>import("@/views/manager/course/CourseUpload")



const routes = [
    ...JobRoutes,
    ...CourseManagerRoutes,
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title:"猿之家 - 互联网技术学习资源 | 让学习成为一种习惯",
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title:"猿之家-登录",
    }
  },
  {
    path: '/box/index.html',
    name: 'box',
    component: BoxView,
    meta: {
      title:"猿之家-软件导航",
    }
  },
  //courseId必须传
  {
    path: '/course/:courseId/:courseMode',
    name: 'course',
    component: CourseDescView,
    meta: {
      title:"课程详情",
    }
  },
  {
    path: '/service',
    name: 'service',
    component: BusinessServiceView,
    meta: {
      title:"猿之家企业服务-一起携手共进",
    }
  },
  ,
  {
    path: '/offline',
    name: 'offline',
    component: OfflineTrainingView,
    meta: {
      title:"线下培训",
    }
  },
  {
    path: '/mycourse',
    name: 'mycourse',
    component: MyCourseView,
    meta: {
      title:"我的课程",
      isAuthRequired:true
    }
  },
  {
    path: '/order',
    name: 'order',
    component: UserOrderView,
    meta: {
      title:"用户订单",
      isAuthRequired:true
    }
  },
  {
    path: '/success',
    name: 'success',
    component: PaySuccessView,
    meta: {
      title:"支付成功",
      isAuthRequired:true
    }
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: NotFoundPageView,
    meta: {
      title:"页面找不到了",
    }
  },
  {
    path: '/error',
    name: 'error',
    component: ServeErrorPageView,
    meta: {
      title:"服务崩溃了",
    }
  },
  {
    path: '/stuservice/:courseName',
    name: 'stuservice',
    component: StudentServiceView,
    meta: {
      title:"学员服务",
      isAuthRequired:true
    }
  },
  {
    path: '/study/:courseID',
    name: 'study',
    component: StudentStudyView,
    meta: {
      title:"课程学习",
      isAuthRequired:true
    }
  },
  ,
  {
    path: '/course/upload',
    name: 'CourseUpload',
    component: CourseUpload,
    meta: {
      title:"课程创建上传",
      // isAuthRequired:true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes
})

import store from "../store"

router.beforeEach((to,from)=>{
  document.title = to.meta.title;
  if(to.meta.isAuthRequired==true && window.localStorage.getItem("apeshome-token")==""){
    router.push("/login")
  }
})


export default router
