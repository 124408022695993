<template>
  <div class="nav">
    <div class="logo">
      <div class="logo-left">
        <img src="@/assets/logo.png" alt="" @click="toIndex()">
      </div>
      <div class="company">
        <div class="company-name">
          猿之家
        </div>
        <div class="company-slogan">
          让学习成为一种习惯
        </div>
      </div>
    </div>
    <div class="menu">
        <span  v-for="(item,index) in menuList"
               :key="index"
               :class="{'current-menu':item.currentMenu}"
               @click="changeMenu(item.value)">{{item.menuName}}</span>
    </div>
    <div v-if="!loginStatus" class="to-login" @click="toLogin()">
      登录
    </div>
    <div v-else class="already-login">
      <span @click="toMyCourse">我的课程</span>
      <img class="header-img" src="@/assets/logo.png" alt="">
      <img v-if="!showItemStatus" class="arrows-down" src="@/assets/images/arrows/down.png" alt="" @click="showUserItem('show')">
      <img v-else class="arrows-down" src="@/assets/images/arrows/up.png" alt="" @click="showUserItem('hidden')">
      <ul v-if="showItemStatus" >
        <li @click="showChangePassword">修改密码</li>
        <li @click="logout()">退出登录</li>
      </ul>
      
      <div class="alter-password" v-show="changePasswordStatusShow">
        <div class="change-title">
          修改密码
        </div>
        <div class="input-password">
          <input type="password" placeholder="请输入原密码" v-model="oldPassword">
        </div>
        <div class="input-password">
          <input type="password" placeholder="请输入新密码" v-model="firstPassword">
        </div>
        <div class="input-password">
          <input type="password" placeholder="确认新密码" @blur="checkPassword" v-model="secondPassword">
        </div>
        <div class="prompt-foo">
          <div class="prompt" v-if="promptMsgShow">{{promptMessageContent}}</div>
        </div>
        <div class="ops-button">
          <button class="cancel" @click="changePassword('cancel')">取消</button>
          <button class="confirm" @click="changePassword('confirm')">确定</button>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script setup>
import {useRouter,useRoute} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {changePasswordAPI} from "@/network/user";
import {getLoginMenuAPI,getNoLoginMenuAPI} from "@/network/menu";
import {Menu} from "@/models/MenuData";
import {useStore} from "vuex";
const store = useStore();

const router = useRouter();
const route = useRoute();
// const menuList = reactive([
//   {"menuName":"课程","currentMenu":true,"value":"course","targetUrl":"/"},
//   {"menuName":"企业服务","currentMenu":false,"value":"service","targetUrl":"/service"},
//   {"menuName":"线下培训","currentMenu":false,"value":"offline","targetUrl":"/offline"},
//   {"menuName":"猿宝箱导航","currentMenu":false,"value":"box","targetUrl":"/box/index.html"},
// ])
const menuList=ref([]);

const toIndex=()=>{
  router.push("/");
}
const loginStatus=ref(false);

// 页面刷新的时候，看一下url是谁，就让谁的状态活跃
onMounted(()=>{

//  获取登陆状态
  const token = window.localStorage.getItem("apeshome-token");

  if(token != "" && token != null){
    loginStatus.value=true;
    // todo 用户登录，获取用户登录的菜单

    if(store.getters.getUserMenu.length==0){
      getLoginMenuAPI().then(res=>{
        let menu = Menu.getInstance(res);
        store.commit("setUserMenu",res);
        console.log(res);
        flushMenu();
      })
    }
    //  @todo 根据token获取用户的信息，然后更新用户的头像状态
  }else{
    if(store.getters.getUserMenu.length==0){
       getNoLoginMenuAPI().then(res=>{
         let menu = Menu.getInstance(res);
         console.log(res)
         store.commit("setUserMenu",res);
         flushMenu();
      })
    }
  }
  flushMenu();
})

const flushMenu=()=>{
  let currentUrl = route.fullPath;
  menuList.value=store.getters.getUserMenu;
  menuList.value.forEach(element=>{
    if(element.targetUrl==currentUrl){
      element.currentMenu=true;
    }else{
      element.currentMenu=false;
    }
  })
}

const toLogin=()=>{
  router.push("/login");

}

const changeMenu=(menuName)=>{
  menuList.value.forEach(element=>{
    element.currentMenu=false;
    if(menuName==element.value){
      element.currentMenu=true;
      router.push(element.targetUrl);
    }
  })
}

// 展示用户退出登录与修改密码的菜单
let showItemStatus=ref(false);
const showUserItem=(showStatus)=>{
  if(showStatus=="show"){
    showItemStatus.value=true;
  }else if(showStatus=="hidden"){
    showItemStatus.value=false;
  }
}

// 退出登录
const logout=()=>{
  window.localStorage.setItem("apeshome-token","");
  // 退出登录后，刷新当前页面
  if(route.fullPath=="/"){
    router.go(0);
  }else{
    router.push("/");
  }

}

// 修改密码框的控制
// 点击了修改密码，同时就要隐藏掉点击下箭头弹出来的框
let changePasswordStatusShow = ref(false);
const showChangePassword=()=>{
  // 关闭当前状态窗口
  showItemStatus.value=false;
  // 展示具体修改密码的窗口
  changePasswordStatusShow.value=true;
}

// 控制密码提示信息是否展示
// 验证密码至少6位，必须包含字母、数字或特殊字符其中的2种
const pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])|(?=.*[@$!%*?&]+).{6,}$/;
let promptMsgShow=ref(false);
let promptMessageContent=ref("");
// 修改密码的具体实现
let oldPassword=ref("");
let firstPassword=ref("");
let secondPassword=ref("");
const checkPassword=()=>{
  if (firstPassword.value!=secondPassword.value){
    promptMsgShow.value=true;
    promptMessageContent.value="两次密码不一致";
    return false;
  }else if (firstPassword.value=="" || secondPassword.value==""){
    promptMsgShow.value=true;
    promptMessageContent.value="密码不可以设置为空";
    return false;
  }else if(!pattern.test(secondPassword.value)){
    promptMsgShow.value=true;
    promptMessageContent.value="密码至少6位，且至少包含字母、数字或特殊字符其中的2种";
    return false;
  }else if(oldPassword.value=="" || oldPassword==null){
    promptMsgShow.value=true;
    promptMessageContent.value="原密码不能为空";
    return false;
  } else{
    promptMsgShow.value=false;
    return true;
  }
}

const changePassword=(type)=>{
    // 点击了取消按钮
    if(type=="cancel"){
      changePasswordStatusShow.value=false;
    //  点击了确认按钮
    }else if(type=="confirm"){
      let status = checkPassword();
      console.log(status)
      if(status){
        changePasswordAPI({
          old_password:oldPassword.value,
          new_password:firstPassword.value,
          repeat_new_password:secondPassword.value
        }).then(res=>{
          console.log(res);
          if(res.status==401){
            promptMessageContent.value=res.detail;
          }else if(res.status==1){
            promptMessageContent.value=res.detail;
            // 刷新当前页面
            router.go(0);
          }else{

          }
        })
      }
    }
}
const toMyCourse=()=>{
  router.push("/mycourse")
}



</script>

<style scoped lang="less">
.nav {
  height: 60px;
  border-bottom: 2px solid #3C3D54;
  line-height: 60px;
  .logo {
    .logo-left{
      float: left;
      img{
        width: 50px;
        height: 50px;
        margin-left: 25px;
        &:hover{
          cursor: pointer;
        }
      }
    }

    .company{
      float: left;
      color:white;
      margin-left: 10px;
      .company-name{
        height: 20px;
        margin-top: 10px;
        line-height: 30px;
        font-size: 16px;
      }
      .company-slogan{
        vertical-align: top;
        line-height: 30px;
        font-size: 12px;
      }
    }
  }
  .menu {
    float: left;
    color:var(--main-clolor);
    span{
      text-align: center;
      height: 50px;
      display: inline-block;
      margin-left: 60px;
      //width: 40px;
      &:hover{
        cursor: pointer;
        color:#00fcb0;
      }
    }
    .current-menu{
      border-bottom: 2px solid #00fcb0;
    }
  }
  .to-login{
    float: right;
    background-color: var(--main-clolor);
    color:#333;
    border-radius: 20px;
    width: 65px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-top: 10px;
    margin-right: 30px;
    &:hover{
      cursor: pointer;
      background-color: #57FFCC;
    }
  }
  .already-login{
    float: right;
    margin-right: 20px;
    span{
      display: inline-block;
      width: 96px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 20px;
      //background-color: var(--main-clolor);
      background-color: #00FCB0;
      color:#333;
      margin-right: 20px;
      &:hover{
        cursor: pointer;
        background-color: #57FFCC;
      }
    }
    .header-img{
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 10px;
    }
    .arrows-down{
      width: 12px;
      margin-right: 20px;
      &:hover{
        cursor: pointer;
      }
    }
    position: relative;
    ul{
      background-color: #424255;
      color: white;
      width: 88px;
      height: 84px;
      position: absolute;
      top: 62px;
      right:20px;
      z-index: 999;
      padding-bottom: 10px;
      border: 1px solid #5D5C75;
      border-radius: 5px;
      li{
        width: 100%;
        height: 36px;
        line-height: 36px;
        margin-top: 6px;
        text-align: center;
        font-size: 14px;
        &:hover{
          cursor: pointer;
          background-color: #72718E;
        }
      }
    }
    .alter-password{
      position: absolute;
      top: 62px;
      right:20px;
      z-index: 999;
      width: 360px;
      height: 321px;
      background-color: #424254;
      border-radius: 10px;
      .change-title{
        border-bottom: 2px solid #55576A;
        text-align: center;
        color: white;
      }
      .input-password{
        width: 304px;
        height: 40px;
        font-size: 14px;
        background-color: #353744;
        color: #999999;
        border-radius: 5px;
        margin: 10px auto;
        line-height: 40px;
        input{
          width: 100%;
          height: 100%;
          padding-left: 10px;
          color: var(--main-clolor);
        }
      }
      .ops-button{
        text-align: right;
        padding-right: 30px;
        button{
          width: 96px;
          height: 40px;
          margin-right: 20px;
        }
        .cancel{
          border: 2px solid white;
          background-color: #424354;
          color: white;
          border-radius: 5px;
        }
        .confirm{
          background-color: #05FCB0;
          color: black;
          border-radius: 5px;
        }

      }
      .prompt-foo{
        height: 20px;
        line-height: 20px;
        text-align: center;
        color:#FF5001;
        font-size: 12px;
      }
    }
  }
}

</style>