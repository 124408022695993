const CourseCreate=()=>import("@/views/manager/course/CourseCreate")
const CourseChapterEdit=()=>import("@/views/manager/course/CourseChapterEdit")
const CourseSectionEdit=()=>import("@/views/manager/course/CourseSectionEdit")
const CourseVideoEdit=()=>import("@/views/manager/course/CourseVideoEdit")
const BiliBiliBack=()=>import("@/views/manager/course/BiliBiliBack")
export default [
    {
        path:"/course/create",
        component:CourseCreate,
        meta:{
            title:"课程创建"
        }
    },
    {
        path:"/course/chapter/edit/:courseId",
        component:CourseChapterEdit,
        meta:{
            title:"课程编辑"
        }
    },
    {
        path:"/course/section/edit",
        component:CourseSectionEdit,
        meta:{
            title:"小节编辑"
        }
    },
    {
        path:"/course/section/video/edit",
        component:CourseVideoEdit,
        meta:{
            title:"视频小节编辑"
        }
    },{
        path:"/bilibili/back",
        component:BiliBiliBack,
        meta:{
            title:"b站回调页面"
        }
    }


]