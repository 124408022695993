import axios from 'axios';
import store from "@/store";
import router from "@/router/index"
export function request(config){
	const instance = axios.create({
		// baseURL:"http://101.200.144.39:8000",
		baseURL:process.env.VUE_APP_BASE_URL,
		timeout:5000,
	})
	
	// 请求拦截
	instance.interceptors.request.use(config=>{
		// 一般来说我们会在这里边给一些实例加上token
		const token = window.localStorage.getItem("apeshome-token");
		if(token){
			// config.headers.Authorization="token";
			config.headers.Authorization="Bearer "+token;
		}else{

		}
		// 直接放行
		return config;
	},err=>{
		// 这里写一些错误的代码在这里
	})
	
	// 响应拦截
	instance.interceptors.response.use(res=>{
		// console.log("响应拦截开始了！！！",res);
		// console.log("响应拦截开始了！！！",res.data.status_code);
		if(res.data.status_code==402){
			window.localStorage.setItem("apeshome-token","");
			alert("登录已过期,请重新登录");
			router.push("/login");
		}else if (res.status>200){
			window.localStorage.setItem("apeshome-token","");
			alert("登录已过期,请重新登录");
			router.push("/login");
		}else if(res.status==200){
			return res.data?res.data:res;
		}
		// if(res.data.status==false){
		// 	window.localStorage.setItem("token","");
		// 	// store.commit("setIsLogin",false);
		// }

		return res.data?res.data:res;
	},err=>{
		// 响应错误的在这里处理，比如404  500这些特殊的状态码
		// 咱们跳转一个特定的处理页面
	})
	
	return instance(config);
} 