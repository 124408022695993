import { createStore } from 'vuex'

export default createStore({
  state: {
    userTelephone:0,
    userMenu:[]
  },
  getters: {
    getUserTelephone:(state)=>{
      return state.userTelephone
    },
    getUserMenu:(state)=>{
      return state.userMenu
    }
  },
  mutations: {
    setUserTelephone(state,payload){
      state.userTelephone=payload;
    },
    setUserMenu(state,payload){
      state.userMenu=payload
    }
  },
  actions: {
  },
  modules: {
  }
})
