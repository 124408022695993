import {request} from "./requestConfig.js"

// 首页所有课
export function getIndexCourseAPI(){
    return request({
        url:"/apeshome/courses",
        method:"get",
    }).then(res=>{
        return res.detail
    })
}
// 根据课程id获取单一课程名称等详情
export function getCourseInfoAPI(course_id){
    return request({
        url:"/apeshome/courses/querybyid/"+course_id,
        method:"get",
    }).then(res=>{
        return res.detail
    })
}

// 根据课程id获取课程章节内容
export function getCourseDescAPI(course_id){
    return request({
        url:"/apeshome/courses/query/coursedetail/"+course_id,
        method:"get",
    }).then(res=>{
        return res.detail
    })
}
// 用户获取我的课程
export function getUserCourseAPI(){
    return request({
        url:"/apeshome/user/getselfcourse/",
        method:"post",
    }).then(res=>{
        return res.detail
    })
}

// 获取课程学习页面左侧的课程大纲
export function getUserCourserOutlineAPI(course_id){
    return request({
        url:"/apeshome/user/getstudyrecord/"+course_id
    }).then(res=>{
        return res.detail
    })
}

// 获取具体节内容
export function getLessonContentAPI(courseID,chapterID,sectionID){
        return request({
            url:"/apeshome/user/query/sectioninfo",
            method:"post",
            data:{
                course_id:courseID,
                chapter_id:chapterID,
                section_id:sectionID
            }
        }).then(res=>{
            return res.detail
        })
}


// 更新课程学习状态
export function updateCourseStudyStatusAPI(course_id,chapter_id,section_id,study_status){
    return request({
        url:"/apeshome/user/setstudyrecord",
        method:"post",
        data:{
            course_id:course_id,
            chapter_id:chapter_id,
            section_id:section_id,
            study_status:study_status
        }
    })
}

// 获取学员最后一次的学习内容
export function getLastStudyRecordAPI(course_id){
    return request({
        url:"/apeshome/user/getlaststudyrecord/"+course_id,
        method:"get",
    }).then(res=>{
        if(res.status_code==1){
            return res.detail
        }else{
            return null
        }
    })
}

// 学员提交学习答案
export function submitAnswerAPI(data){
    return request({
        url:"/apeshome/user/setstudytestrecord",
        method:"post",
        data
    })
}

// 获取学习视频
export function getVideoAPI(key){
    return request({
        url:"/apeshome/manager/gettsurl/"+key,
        method:"post",
    })
}

// 获取学员已经回答的问题答案
export function getStudentAnswerAPI(course_id,chapter_id,section_id){
    return request({
        url:"/apeshome/user/gettestrecorddetail",
        method:"post",
        data:{
            course_id:course_id,
            chapter_id:chapter_id,
            section_id:section_id
        }
    }).then(res=>{
        if(res.status_code==1){
            return res.detail
        }else{
            return null
        }
    })
}