<template>
  <div style="clear:both"></div>
  <div class="business">
      <div class="business-title">
        <span>
          猿之家有哪些业务板块?
        </span>
      </div>
    <div class="business-info clearfix">
      <div class="type" v-for="(item,key) in businessTypes" :key="key">
        <div class="type-img">
          <img :src="item.imagePath" alt="">
        </div>
        <div class="type-title">
          {{item.typeTitle}}
        </div>
        <div class="type-info">
          {{item.typeInfo}}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {reactive} from "vue";

  let businessTypes = reactive(
      [
        {
          typeTitle:"在线实战课程",
          // 这里是从public路径里边读取的，走了网络请求
          imagePath:"/images/business-type/1.png",
          typeInfo:"【快马加鞭研发中】教学案例均来自各个行业的实际需求，可以切实有效地提升现有工作效率，案例涵盖营销传媒、电商、教育、金融等数字化前沿领域；"
        },
        {
          typeTitle:"校园实训",
          imagePath:"/images/business-type/2.png",
          typeInfo:"【紧锣密鼓开展中】已与多所高校进行了各种长期及短期的实训，实训时间3天到21天不等，根据实际需求开展；"
        },
        {
          typeTitle:"线下培训",
          imagePath:"/images/business-type/3.png",
          typeInfo:"【紧锣密鼓开展中】已开展软件测试工程师、PLC电气工程师、新媒体运营工程师等多方向的工程师培养；"
        },
        {
          typeTitle:"企业服务",
          imagePath:"/images/business-type/4.png",
          typeInfo:"【疯狂对接落地中】已与多家企业展开企业内训、企业定制化开发、企业人才定制化培养、人才输送等多方向合作；"
        },
        {
          typeTitle:"定制化培养",
          imagePath:"/images/business-type/5.png",
          typeInfo:"【疯狂对接落地中】已与国内多家企业开展各类定制化工程师培养服务，目前已开展的方向包含测试工程师、PLC电气工程师、新媒体运营工程师；"
        },
      ]
  )
</script>

<style scoped lang="less">
  .business {
    margin-top: 40px;
    color: white;
    padding-bottom: 40px;
    .business-title{
      text-align: center;
      span {
        font-size: 32px;
        background: linear-gradient(270deg, #8ff49b 0%, #6eceff 46%, #dbcaff 100%);/* 渐变色 */
        -webkit-background-clip: text; /* 将渐变色裁剪到文字形状 */
        //background-clip: text; /* 标准属性，将渐变色裁剪到文字形状 */
        color: transparent; /* 让文字变成透明，以便显示渐变背景 */
      }
    }
    .business-info{
      width: 1300px;
      margin: 0 auto;
      .type{
        float: left;
        margin-top: 20px;
        border:1px solid #4E4E5E;
        width: 200px;
        margin-left: 20px;
        height: 200px;
        border-radius: 10px;
        padding: 10px 10px;
        &:hover{
          cursor: pointer;
          border: 1px solid #FF5001;
        }
        .type-img{
          img{
            width: 28px;
            height: 28px;
          }

        }
        .type-title{
          margin-top: 15px;
          font-size: 18px;
        }
        .type-info{
          margin-top: 10px;
          font-size: 14px;
          color: #C7C7CA;
          line-height: 20px;
        }
      }
    }
  }
</style>