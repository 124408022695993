<template>
  <div class="footer">
    <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">京ICP备2023004840号</a>
  </div>
</template>

<script setup>

</script>

<style scoped lang="less">
.footer{
  height: 60px;
  line-height: 60px;
  background-color: #1C1C29;
  text-align: center;
  a{
    color:#A4A4A9;
    font-size: 14px;
  }
}
</style>