import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/css/config.css"

// npm install md-editor-v3
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import Prism from 'prismjs';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 下边是markdown文档预览相关的组件
// 全套使用的是https://code-farmer-i.github.io/vue-markdown-editor/zh/
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
// highlightjs
import hljs from 'highlight.js';
VMdPreview.use(githubTheme, {
    Hljs: hljs,
});


VueMarkdownEditor.use(vuepressTheme, {
    Prism,
    markdownOptions: {
        html: true, // 启用HTML解析
    },
});

const app = createApp(App)
// 全局错误处理器
app.config.errorHandler = (err, instance, info) => {
    // 对错误进行记录、上报或显示友好错误提示
    console.error(`捕获到全局错误: ${err.message}`);
    // 可选地，可以根据 err.vm 获取与错误相关的组件实例信息
    console.log(instance);
    // 可选地，可以根据 info 获取错误所在的生命周期钩子
    console.log(info);
    // 跳转登陆页
    router.push('/error');
    // 取消默认错误弹出
    return true; // 表示已处理此错误，不再进一步传播
};
app.use(store)
    .use(router)
    .use(VueMarkdownEditor)
    .use(ElementPlus)
    .use(VMdPreview)
    .mount('#app')
