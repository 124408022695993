<template>
<!--  <div>{{currentCourses}}</div>-->
  <div class="job">
    <div class="name">
<!--      <span>{{currentCourses.typeName}}</span>-->
      <span>{{currentCourses.type_name}}</span>
<!--      <span>查看更多&nbsp;></span>-->
    </div>
    <div class="course-info clearfix">
      <div class="every-course fl"
           v-for="(item,key) in currentCourses.courses"
           :key="key"
           @click="toCourseDesc(
               item.course_id,
               item.course_mode,
               item.is_free
               )"
      >
        <div class="image-title" :style="{'backgroundImage': 'url(' + item.course_icon + ')'}">
          <div class="course-name">{{item.course_name}}</div>
          <div class="is-free">{{item.is_free==false?'收费':'免费'}}</div>
        </div>
        <div class="desc dian2">{{item.course_desc}}</div>
        <div class="labels">
          <span v-for="(tag,index) in item.courseTags">{{tag}}</span>
        </div>
        <div class="price clearfix">
          <div class="sale">￥{{item.sell_price}}</div>
          <div class="original">￥{{item.price}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import {onMounted} from "vue";
const router = useRouter();
let homeValue = defineProps(["courseList"])
let currentCourses = homeValue.courseList;
console.log(currentCourses);
const toCourseDesc=(courseID,courseMode,isFree)=>{
  // 根据课程id，拿到课程所有信息，然后传到下一个页面
  if(isFree==false){
    // 跳转到付费课程页面
    // router.push("/course/"+courseID+"/"+courseMode+"/pay");
    router.push("/course/"+courseID+"/"+courseMode);
  }else{
    // 跳转到免费课程页面
    router.push("/study/"+courseID+"?mode=true");
  }
}
// console.log(currentCourses);
for (let i=0; i<currentCourses.courses.length;i++){
  currentCourses.courses[i].courseTags=currentCourses.courses[i].course_label.split(",");
  if(currentCourses.courses[i].course_icon==null){
    currentCourses.courses[i].course_icon="/images/course-header/6.png";
  }
}


</script>

<style scoped lang="less">
.job {
  color: white;
  height: 443px;
  .name {
    padding-left: 3px;
    padding-bottom: 10px;
    margin: 0 20px 0 16px;
    span:nth-child(2){
      float: right;
      &:hover{
        cursor: pointer;
        color: var(--main-clolor);
      }
    }
    border-bottom: 1px solid #62626F;
  }
  //  每一个课程的样式
  .course-info {
    padding-left: 50px;
    width: 95%;
    margin: 0 auto;
    .every-course{
      float: left;
      margin-top: 20px;
      margin-left: 30px;
      width: 384px;
      &:hover{
        cursor: pointer;
        color:var(--main-clolor);
      }
      .image-title{
        //width: 384px;
        height: 216px;
        //background: url("@/assets/images/course/特价.jpg") no-repeat;
        background-repeat: no-repeat;
        background-size: cover; /* 设置背景图片尺寸为盒子大小，cover 表示尽量覆盖整个盒子 */
        border-radius: 10px;
        //设置子盒子底部对齐
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        //align-items: center;
        .course-name {
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          height: 50px;
          font-size: 24px;
          background-color: rgba(0, 0, 0, 0.6);
          text-align: center;
          line-height: 50px;

        }
        position: relative;
        .is-free{
          position: absolute;
          top: 0px;
          right: 0px;
          color:white;
          border: 2px solid #FF5655;
          background-color: #FF5655;
          width: 60px;
          height: 20px;
          text-align: center;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
      .desc {
        color:#B8B8B8;
        margin-top: 10px;
      }
      .labels{
        margin: 12px 0 16px;
        span{
          display: inline-block;
          background: #1e3742;
          border-radius: 3px;
          font-size: 12px;
          color: #01fbb0;
          padding: 4px 6px;
          margin-right: 10px;
        }
      }
      .price{
        .sale{
          float: left;
          color: #ff5555;
          font-size: 18px;
        }
        .original{
          float: left;
          color: #888686;
          font-size: 14px;
          padding-left: 12px;
          text-decoration: line-through;
          margin-top: 3px;
        }
      }
    }
  }
}
</style>