import {reactive} from "vue";

export class Menu{
    // 这里需要用单例模式实现，要不然每次刷新页面就重置了菜单栏了
    constructor(data) {
        if (Menu.hasOwnProperty('instance')) {
            Menu.instance = this;
        }else{
            this.menuList=data;
            for(let i=0;i<this.menuList.length;i++){
                this.menuList[i].menuName=data[i].title;
                this.menuList[i].currentMenu=data[i].default_menu;
                this.menuList[i].value=data[i].name;
                this.menuList[i].targetUrl=data[i].component;
            }
        }
    }
    // 静态公共方法，用于获取单例对象实例
    static getInstance(data) {
        if (!Menu.hasOwnProperty('instance')) {
            Menu.instance = new Menu(data);
        }
        return Menu.instance;
    }
}

