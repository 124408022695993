import {request} from "./requestConfig.js"

//获取未登录时候的菜单
export function getNoLoginMenuAPI(){
    return request({
        url:"/apeshome/index",
    }).then(res=>{
        return res.detail
    })
}


//获取登录时候的菜单
export function getLoginMenuAPI(){
    return request({
        url:"/apeshome/user/index",
    }).then(res=>{
        return res.detail
    })
}