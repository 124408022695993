import {request} from "./requestConfig.js"

// 密码登录接口
export function loginAPI(data){
    return request({
        url:"/apeshome/user/login",
        method:"post",
        headers:{
            'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(res=>{
        return res.detail
    })
}
//短信登录
export function loginMsgAPI(data){
    return request({
        url:"/apeshome/user/login2",
        method:"post",
        headers:{
            // 'Content-type': 'application/x-www-form-urlencoded'
        },
        data
    }).then(res=>{
        return res.detail
    })
}

// 获取手机验证码
export function getVerificationCode(phone){
        return request({
            url:"/apeshome/user/getverificode/"+phone,
        }).then(res=>{
            return res.detail
        })
}
// 用户注册
export function userRegister(data){
    return request({
        url:"/apeshome/user/register",
        method:"post",
        data
    })
}

export function logout(data){
    return request({
        url:"/login/",
        method:"post",
        data
    })
}

// 修改密码
export function changePasswordAPI(data){
    return request({
        url:"/apeshome/user/modify_password",
        method:"post",
        data
    })
}